.atendimento-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 40px 0;
}

.atendimento-container h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.lista-ruas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    width: 80%;
}

.lista-ruas input {
    margin-bottom: 10px;
    width: 3%;
}

/* .lista-ruas input:hover {
    background-color: gray;
    border-radius: 50%;
} */

.lista-ruas iframe {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.lista-ruas span {
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 18px;
    font-family: 'Walkway Bold';
    letter-spacing: 2px;
}

#text-agenda {
    font-family: 'Walkway Bold';
    letter-spacing: 2px;
}


#copyright {
    text-align: center;
    padding: 20px 0;
    font-family: 'Walkway Bold';
    letter-spacing: 2px;
}

@media screen and (max-width: 480px) {
    .atendimento-container h3 {
        font-size: 18px;
        text-align: left;
        padding-left: 10px;
    }

    .lista-ruas {
        width: 100%;
    }

    .lista-ruas span {
        font-size: 12px;
        padding-left: 10px;
    }

    .lista-ruas input {
        width: 7%;
    }
}