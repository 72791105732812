.contact-container {
    /* background-color: #c99e67; */
    background-color: rgba(201, 158, 103, 0.6);;
    color: black;
    display: flex; 
    justify-content: space-around;
    padding-right: 10px;
}

.icon8 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 10px;
    width: 10%;
}

.icon8 a {
    color:  black;
    text-decoration: none;
    list-style: none;
    font-size: 12px;
}

.icon8 a:hover {
    color:  gold;
}

.contact-div {
    margin-bottom: 50px;
}

#contact {
    text-align: center;
}

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

#input-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Brillantly';
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #1e2c51;
}

#span-oab {
    font-family: 'Walkway Bold';
    letter-spacing: 2px;
}

#text-agenda {
    font-weight: 700;
}

#input-name input {
    cursor: auto;
}

#input-oab {
    text-align: center;
}

.contact {
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    letter-spacing: 2px;
}

.contact-links {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Walkway bold';
}

.contact-links span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-links span input {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 20%;
}

.icons-footer {
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
}

.icons-footer input {
    width: 40%;
}

/* #input-zap {
    border: 1px solid black;
} */

.footer-image{
    display: flex;
    align-items: center;
    width: 10%;
}

/* .footer-image img {
    box-shadow: rgba(255, 215, 0, 0.4) -5px 5px, rgba(255, 215, 0, 0.3) -10px 10px, rgba(255, 215, 0, 0.2) -15px 15px, rgba(255, 215, 0, 0.1) -20px 20px, rgba(255, 215, 0, 0.05) -25px 25px;
    border-radius: 5px;
    width: 100%;
} */


@media screen and (max-width: 480px) {
    .contact-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-links {
        color: black;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        margin-top: 20px;
        font-family: 'Walkway bold';
    }
    
    .contact-links span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
    }
    
    .contact-links span input {
        padding-bottom: 5x;
        width: 20%;
    }
    
    .footer-image {
        display: none;
    }

    .lista-ruas {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0px;
    }

    .lista-ruas span {
        font-size: 12px;
    }


    .icon8 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .icon8 a {
        color:  black;
        text-decoration: none;
        list-style: none;
        font-size: 12px;
    }
}