@media screen and (max-width: 480px) {
  .about-container {
    display: flex;
    flex-direction: column;
  }

  .description-container {
    display: flex;
    flex-direction: column;
  }

  .description-container h2 {
    text-align: center;
    
  }

  #text {
    font-size: 1.25rem;
    padding-top: 40px;
    text-align: left;
    font-family: 'Walkway Bold';
    letter-spacing: 2px;
    margin-left: 20px;
    margin-right: 10px;
    text-align: left;
}

.img-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.img-container img {
  width: 100%;
}
}

@media screen and (min-width: 481px) {
    .about-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .description-container {
        display: flex;
        flex-direction: column;
        margin-left: 100px;
    }

    #text {
      font-size: 1.25rem;
      padding-top: 40px;
      text-align: left;
      font-family: 'Walkway Bold';
      letter-spacing: 2px;
  }

    .img-container {
      width: 180%;
      display: flex;
      justify-content: center;
      padding-left: 80px;
      padding-right: 80px;
  }
  
  .img-container img {
      width: 100%;
  }
}

.description-container h2 {
    color: black;
    padding-top: 20px;
    font-weight: bold;
    font-family: 'Brillantly';
    letter-spacing: 3px;
    color: #1e2c51;
}
