@media screen and (min-width: 481px) {
    .mission-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        margin: 0 auto;
        height: 100%;
    }
    
    .mission-container h2 {
        font-family: 'Walkway Condensed Bold';
        letter-spacing: 4px;
        padding-top: 10px;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 1);
    }
    
    /* .mission-container img {
        padding: 20px;
        width: 10%;
    } */
    
    .mission-container span {
        font-size: 1.25rem;
        font-weight: 500;
        text-align: left;
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        padding-top: 10px;
        padding-left: 20px;
    }
    
}


@media screen and (max-width: 480px) {
    .mission-container {
        margin: 0;
        padding-left: 10px;
    }

    /* .mission-container img {
        width: 30%;
    } */

    .mission-container span {
        text-align: left;
        font-family: 'Walkway Bold';
    }
}