@media screen and (max-width: 480px) {
    .container-primario img {
        width: 10%;
    }

    .direitos-cotainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .direitos-cotainer a {
        background-color: #c79f64;
        border: 1px solid #1e2c51;
        border-radius: 10px;
        width: 100%;
        margin-top: 40px;
        padding: 20px;
        font-size: 22pt;
        color: #1e2c51;
        cursor: pointer;
        font-family: 'Walkway Condensed Bold';
        letter-spacing: 4px;
        text-transform: uppercase;
        text-decoration: none;
        list-style: none;
        text-align: center;
    }


    .atuações-explicações {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 50px 0;
        margin: 0 10px;
        width: 100%;
        background-color: #1e2c51;
        padding-left: 10px;
    }

    /* .atuações-explicações h4 {
        margin: 10px;
        font-size: 20px;
        text-align: left;
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
    } */

    .atuações-explicações p {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        font-size: 15pt;
        color: #c79f64;
    }

    .atuações-explicações h4 {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        padding-bottom: 5px;
        color: #c79f64;
    }

    #h4-sucessorio {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #h4-sucessorio span{
        text-transform: uppercase;
    }


    .atuações-explicações-digital {
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 25px 0;
        margin: 0 10px;
        width: 100%;
        padding-left: 10px;
        background-color: #1e2c51;
    }

    /* .atuações-explicações-digital p {
        margin: 10px;
        font-size: 20px;
        text-align: left;
    } */

    .atuações-explicações-digital p {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        font-size: 15pt;
        color: #c79f64;
    }

    .atuações-explicações-digital h4 {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        padding-bottom: 5px;
        color: #c79f64;
    }

    .atuações-container {
        display: flex;
        flex-wrap: wrap;
    }

    .atuações-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin: 0 10px;
        margin-bottom: 10px;
        padding: 50px 0;
        width: 100%;
        background-color: #c79f64;
    }

    .atuações-cards-digital {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin: 0 10px;
        margin-bottom: 10px;
        padding: 50px 0;
        width: 100%;
        background-color: #c79f64;
    }
}

@media screen and (min-width: 481px) {
    .container-primario img {
        width: 4%;
    }

    .container-primario h2 {
        margin-left: 530px;
        margin-right: 530px;
    }

    .direitos-cotainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-left: 70px;
        width: 100%;
    }

    .direitos-cotainer a {
        background-color: #c79f64;
        border: 1px solid #1e2c51;
        border-radius: 10px;
        margin-top: 40px;
        padding: 20px;
        font-size: 22pt;
        color: #1e2c51;
        cursor: pointer;
        font-family: 'Walkway Condensed Bold';
        letter-spacing: 4px;
        text-transform: uppercase;
        text-decoration: none;
        list-style: none;
        z-index: 1;
    }

    .direitos-cotainer a:hover {
        background-color: #1e2c51;
        border: 1px solid #c79f64;
        color: #c79f64;
    }

    .atuações-explicações {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 25px 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 10px;
        background-color: #1e2c51;
    }

    .atuações-explicações p {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        font-size: 15pt;
        color: #c79f64;
    }

    .atuações-explicações h4 {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        padding-bottom: 5px;
        color: #c79f64;
    }

    #h4-sucessorio {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #h4-sucessorio span{
        text-transform: uppercase;
    }

    .atuações-explicações-digital {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 25px 0;
        width: 30%;
        padding-left: 10px;
        background-color: #1e2c51;
    }

    .atuações-explicações-digital p {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        font-size: 15pt;
        color: #c79f64;
    }

    .atuações-explicações-digital h4 {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
        padding-bottom: 5px;
        color: #c79f64;
    }

    .atuações-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin: 0 85px;
    }

    .atuações-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 50px 0;
        width: 50%;
        background-color: #c79f64;
    }

    .atuações-cards-digital {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 50px 0;
        width: 32%;
        background-color: #c79f64;
    }

}


.container-primario {
    /* background-color: #d1ecf7; */
    /* background-color: rgba(15, 28, 35, 0.6); */
    /* background-color: rgba(200, 157, 102, 0.4);  */
    margin-top: 50px;
    padding-bottom: 10px;
    position: relative;
    /* z-index: 1; */
}

.container-primario::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../public/Images/Pattern_logomarca-DeniseLacerda.png);
    opacity: 0.35;
    background-size: 100%;
    z-index: -1;
}

.container-primario h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 80px;
    font-family: 'Walkway Condensed Bold';
    letter-spacing: 4px;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 1);
    font-size: 22pt;
}

.container-primario img {
    margin-left: 5px;
}

#title-area {
    padding-top: 55px;
    padding-bottom: 10px;
    text-align: center;
    font-family: 'Walkway Condensed Bold';
    letter-spacing: 6px;
    text-transform: uppercase;
}

.atuações-cards:hover {
    /* box-shadow: rgb(255, 223, 0, 0.6) 0px 2px 4px 0px, rgb(255, 223, 0, 0.6) 0px 2px 16px 0px; */
    cursor: pointer;
}

.atuações-cards-digital:hover {
    /* box-shadow: rgb(255, 223, 0, 0.6) 0px 2px 4px 0px, rgb(255, 223, 0, 0.6) 0px 2px 16px 0px; */
    cursor: pointer;
}

.atuações-explicações:hover {
    /* box-shadow: rgb(255, 223, 0, 0.6) 0px 2px 4px 0px, rgb(255, 223, 0, 0.6) 0px 2px 16px 0px; */
    cursor: pointer;
}

.atuações-explicações-digital:hover {
    /* box-shadow: rgb(255, 223, 0, 0.6) 0px 2px 4px 0px, rgb(255, 223, 0, 0.6) 0px 2px 16px 0px; */
    cursor: pointer;
}

.atuações-cards img {
    width: 15%;
    padding-top: 5px;
    padding-bottom: 20px;
}

.atuações-cards h4 {
    padding-bottom: 10px;
    font-family: 'Walkway Bold';
    letter-spacing: 2px;
    text-transform: uppercase;
}

.atuações-cards-digital img {
    width: 15%;
    padding-top: 5px;
    padding-bottom: 20px;
}

.atuações-cards-digital h4 {
    font-family: 'Walkway Bold';
    letter-spacing: 2px;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.atuações-cards p {
    font-family: 'Walkway SemiBold';
    letter-spacing: 1px;
}

.atuações-cards-digital p {
    font-family: 'Walkway SemiBold';
    letter-spacing: 1px;
}