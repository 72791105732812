.header-container {
    background-image: url(../../public/Images/backgroud-lawyer.jpg);
    background-size: cover;
    height: 100vh;
}

.header {
    background-color: rgba(15, 28, 35, 0.9);
    width: 100%;
    font-weight: 700;
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 2;
}

@media screen and (max-width: 480px) {
    .header {
        display: flex;
        flex-direction: column;
    }

    .header input {
        align-self: flex-start;
        padding-left: 10px;
    }

    #list-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        list-style: none;
    }

    #list-container-mobile a {
        color: white;
        text-decoration: none;
    }
    
    /* #list-container-mobile a:hover {
        color: #ffd700;
    } */

    #list-container-mobile a {
        margin-bottom: 10px;
        font-family: 'Brillantly';
        letter-spacing: 2px;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .newText-container {
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .newText-container h2 {
        text-align: center;
        font-family: 'Walkway Bold';
    }

    .newText-container p {
        text-align: center;
    }

    .icons {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
    }

    .icons input {
        margin: 10px;
    }

    .newText-container img {
        width: 90%;
    }
}

@media screen and (min-width: 451px) and (max-width: 720px){
    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .newText-container h2 {
        text-align: center;
        font-family: 'Walkway Bold';
    }

    .newText-container p {
        text-align: center;
    }

    #text-mission img {
        width: 5%;
        margin-right: 10px;
    }

    .icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
     }
 
     .icons input {
         margin: 10px;
     }
 
}
    
@media screen and (min-width: 721px) {
    @keyframes animação {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    #logo {
        /* background-color: #ffefd5; */
        border-radius: 10px;
        width: 100%;
    }

    .newText-container h2 {
        font-family: 'Walkway Bold';
        letter-spacing: 2px;
    }
    

    .icons {
        display: flex;
        height: 40%;
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
        position: fixed;
        padding-left: 25px;

        animation-name: animação;
        animation-duration: 2s;
    }


    #text-mission img {
        width: 5%;
        margin-right: 10px;
    }
}

#text-header {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 20px;
}

#text-mission {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

#list-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    list-style: none;
}

#logo-header {
    width: 3%;
}

#list-container li {
    cursor: pointer;
}

#list-container li a {
    color: white;
    text-decoration: none;
    font-family: 'Brillantly';
    letter-spacing: 2px;
}

#list-container li a:hover {
    color: #c99e67;
}

#list-conatiner li img {
    width: 10px;
}

.text-container {
    background-color: rgba(15, 28, 35, 0.9);
    color: white;
    height: 100vh;
    width: 100%;
    display: flex;
}

.newText-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin: 0 auto;
}

/* img {
    width: 40%;
    margin-bottom: 10px;
    margin-top: 10px;
} */

.text-container h2 {
    font-weight: 700;
    padding-top: 10px;
}

.text-container p {
    font-size: 1.5rem;
    font-weight: 500;
}


/* .icons {
    margin-top: 100px;
    display: flex;
    height: 40%;
    flex-direction: column;
    justify-content: space-between;
} */

/* .icons input {
    width: 40%;
} */