* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Brillantly';
  src: url('../public/fontes/Brilliantly.otf');
}

@font-face {
  font-family: 'Walkway Black RevOblique';
  src: url('../public/fontes/Walkway Black RevOblique.ttf');
}

@font-face {
  font-family: 'Walkway Black';
  src: url('../public/fontes/Walkway Black.ttf');
}

@font-face {
  font-family: 'Walkway Bold RevOblique';
  src: url('../public/fontes/Walkway Bold RevOblique.ttf');
}

@font-face {
  font-family: 'Walkway Bold';
  src: url('../public/fontes/Walkway Bold.ttf');
}

@font-face {
  font-family: 'Walkway Condensed Bold';
  src: url('../public/fontes/Walkway Condensed Bold.ttf');
}

@font-face {
  font-family: 'Walkway Condensed SemiBold';
  src: url('../public/fontes/Walkway Condensed SemiBold.ttf');
}

@font-face {
  font-family: 'Walkway Condensed';
  src: url('../public/fontes/Walkway Condensed.ttf');
}

@font-face {
  font-family: 'Walkway Expand Black';
  src: url('../public/fontes/Walkway Expand Black.ttf');
}

@font-face {
  font-family: 'Walkway Expand Bold';
  src: url('../public/fontes/Walkway Expand Bold.ttf');
}

@font-face {
  font-family: 'Walkway Expand RevOblique';
  src: url('../public/fontes/Walkway Expand RevOblique.ttf');
}

@font-face {
  font-family: 'Walkway Expand SemiBold';
  src: url('../public/fontes/Walkway Expand SemiBold.ttf');
}

@font-face {
  font-family: 'Walkway Expand UltraBold';
  src: url('../public/fontes/Walkway Expand UltraBold.ttf');
}

@font-face {
  font-family: 'Walkway Expand';
  src: url('../public/fontes/Walkway Expand.ttf');
}

@font-face {
  font-family: 'Walkway Oblique Black';
  src: url('../public/fontes/Walkway Oblique Black.ttf');
}

@font-face {
  font-family: 'Walkway Oblique Bold';
  src: url('../public/fontes/Walkway Oblique Bold.ttf');
}

@font-face {
  font-family: 'Walkway Oblique Expand';
  src: url('../public/fontes/Walkway Oblique Expand.ttf');
}

@font-face {
  font-family: 'Walkway Oblique SemiBold';
  src: url('../public/fontes/Walkway Oblique SemiBold.ttf');
}

@font-face {
  font-family: 'Walkway Oblique UltraBold';
  src: url('../public/fontes/Walkway Oblique UltraBold.ttf');
}

@font-face {
  font-family: 'Walkway Oblique';
  src: url('../public/fontes/Walkway Oblique.ttf');
}

@font-face {
  font-family: 'Walkway RevOblique';
  src: url('../public/fontes/Walkway RevOblique.ttf');
}

@font-face {
  font-family: 'Walkway rounded';
  src: url('../public/fontes/Walkway rounded.ttf');
}

@font-face {
  font-family: 'Walkway SemiBold RevOblique';
  src: url('../public/fontes/Walkway SemiBold RevOblique.ttf');
}

@font-face {
  font-family: 'Walkway SemiBold';
  src: url('../public/fontes/Walkway SemiBold.ttf');
}

@font-face {
  font-family: 'Walkway UltraBold RevOblique';
  src: url('../public/fontes/Walkway UltraBold RevOblique.ttf');
}

@font-face {
  font-family: 'Walkway UltraBold';
  src: url('../public/fontes/Walkway UltraBold.ttf');
}

@font-face {
  font-family: 'Walkway UltraCondensed Bold';
  src: url('../public/fontes/Walkway UltraCondensed Bold.ttf');
}

@font-face {
  font-family: 'Walkway UltraCondensed Semi';
  src: url('../public/fontes/Walkway UltraCondensed Semi.ttf');
}

@font-face {
  font-family: 'Walkway UltraCondensed';
  src: url('../public/fontes/Walkway UltraCondensed.ttf');
}

@font-face {
  font-family: 'Walkway UltraExpand Bold';
  src: url('../public/fontes/Walkway UltraExpand Bold.ttf');
}

@font-face {
  font-family: 'Walkway UltraExpand SemiBold';
  src: url('../public/fontes/Walkway UltraExpand SemiBold.ttf');
}

@font-face {
  font-family: 'Walkway UltraExpand';
  src: url('../public/fontes/Walkway UltraExpand.ttf');
}